var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-container" },
    [
      !_vm.listId || _vm.listId == "preferred" ? _c("ListPreferred") : _vm._e(),
      _c("ListDetail", { attrs: { listId: _vm.listId, showSectors: false } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }