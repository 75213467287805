var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.list
    ? _c(
        "div",
        {
          staticClass:
            "list-detail-container d-flex flex-column scroll-container"
        },
        [
          _c(
            "h1",
            {
              staticClass:
                "secondary--text font-weight-bold pl-3 pl-sm-0 py-3 py-sm-0"
            },
            [_vm._v(" " + _vm._s(_vm.list.name) + " ")]
          ),
          _vm.list.wishlistItems.length +
            _vm.list.wishlistInactiveItems.length >
          0
            ? _c(
                "div",
                { staticClass: "list rounded-md pa-2 pa-sm-4 px-md-0" },
                [
                  _c("v-switch", {
                    staticClass: "wishlist-switch d-print-none",
                    attrs: { label: "Dividi per categoria" },
                    model: {
                      value: _vm.showSectors,
                      callback: function($$v) {
                        _vm.showSectors = $$v
                      },
                      expression: "showSectors"
                    }
                  }),
                  _vm.$vuetify.breakpoint.smAndUp
                    ? _c(
                        "v-row",
                        {
                          staticClass:
                            "text-body-2 secondary--text font-weight-bold mb-3",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4", md: "4", lg: "5" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lists.header.product")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3", sm: "4", md: "3" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lists.header.unitPrice")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4", lg: "2", md: "3" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lists.header.qty")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.list && !_vm.showSectors
                    ? _c(
                        "v-list",
                        { staticClass: "wishlist" },
                        [
                          _vm._l(_vm.list.wishlistItems, function(item) {
                            return _c("ListItem", {
                              key: item.itemId,
                              staticClass: "py-5 wish-list-item",
                              attrs: { item: item, isActive: true },
                              on: {
                                removeItem: _vm.removeFromList,
                                update: _vm.updateList
                              }
                            })
                          }),
                          _vm._l(_vm.list.wishlistInactiveItems, function(
                            item
                          ) {
                            return _c("ListItem", {
                              key: item.itemId,
                              staticClass: "py-5 wish-list-item",
                              attrs: { item: item, isActive: false },
                              on: { removeItem: _vm.removeFromList }
                            })
                          })
                        ],
                        2
                      )
                    : _c(
                        "v-expansion-panels",
                        {
                          attrs: {
                            accordion: "",
                            flat: "",
                            multiple: "",
                            tile: ""
                          },
                          model: {
                            value: _vm.panel,
                            callback: function($$v) {
                              _vm.panel = $$v
                            },
                            expression: "panel"
                          }
                        },
                        _vm._l(_vm.itemGroups, function([categoryId, group]) {
                          return _c(
                            "v-expansion-panel",
                            { key: categoryId },
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  staticClass:
                                    "text-caption primary--text grey lighten-3 px-3",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return (() => {}).apply(null, arguments)
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "actions",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "secondary",
                                                  small: ""
                                                }
                                              },
                                              [_vm._v(" $chevronDown ")]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center black--text"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "font-weight-bold mr-2"
                                        },
                                        [_vm._v(_vm._s(group.name))]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              `(${group.products.length})`
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "v-expansion-panel-content",
                                _vm._l(group.products, function(item) {
                                  return _c("ListItem", {
                                    key: item.itemId,
                                    staticClass: "py-5",
                                    attrs: {
                                      item: item,
                                      isActive: item.isActive
                                    },
                                    on: {
                                      removeItem: _vm.removeFromList,
                                      update: _vm.updateList
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                ],
                1
              )
            : _vm._e(),
          _vm.list.wishlistItems.length +
            _vm.list.wishlistInactiveItems.length ==
          0
            ? _c(
                "v-card",
                { attrs: { light: "", depresses: "", elevation: "0" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-body-1 px-0 py-4 font-weight-bold" },
                    [_vm._v(_vm._s(_vm.$t("lists.noProducts")))]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            {
              staticClass: "mt-6 mb-10 wishlist-actions",
              attrs: {
                "no-gutters": "",
                justify: "center",
                "justify-md": "space-between"
              }
            },
            [
              _c(
                "v-btn",
                {
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "mb-3 secondary--text"
                    : "px-0 secondary--text",
                  attrs: {
                    depressed: "",
                    large: "",
                    outlined: _vm.$vuetify.breakpoint.smAndDown,
                    to: { name: "Lists" },
                    color: "transparent",
                    block: _vm.$vuetify.breakpoint.smAndDown,
                    plain: ""
                  }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-3", attrs: { color: "secondary" } },
                    [_vm._v("$chevronLeft")]
                  ),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("lists.button.backToLists")))
                  ])
                ],
                1
              ),
              _c(
                "div",
                { class: _vm.$vuetify.breakpoint.smAndDown ? "w-100" : "" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3 mb-md-0",
                      attrs: {
                        outlined: "",
                        large: "",
                        color: "secondary",
                        block: _vm.$vuetify.breakpoint.smAndDown,
                        depressed: "",
                        disabled:
                          _vm.list.wishlistItems.length +
                            _vm.list.wishlistInactiveItems.length ==
                          0
                      },
                      on: {
                        click: function($event) {
                          return _vm.emptyList()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lists.button.emptyList")) + " "
                      )
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-md-3",
                      attrs: {
                        large: "",
                        color: "primary",
                        block: _vm.$vuetify.breakpoint.smAndDown,
                        depressed: "",
                        disabled:
                          _vm.list.wishlistItems.length +
                            _vm.list.wishlistInactiveItems.length ==
                          0
                      },
                      on: { click: _vm.addAllToCart }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lists.button.addAllToCart")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }